import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import { GET_INFO, CHECKING_OUT } from '../redux/ducks/actions/ActionTypes';
import { Tooltip, AutoComplete, Input, Button, Menu, Typography } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from "moment";
import { useQueryParam, StringParam } from 'use-query-params';
import { toast } from 'react-toastify';
import { File, Star, Activity } from "react-feather";
import { Footer } from '../components/util';

const { SubMenu } = Menu;
const { Title } = Typography;

function capitalizeFirstWord(str){
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}

export default function Dashboard() {

    const dispatch = useDispatch();
    const infoData = useSelector((state) => state.init.infoData)

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if(!infoData) {
                dispatch({
                    type: GET_INFO
                })
            }
        };
    
        fetchData();
    }, [dispatch, infoData]);
    const toastError = (msg) => toast(msg,{type: "error", isLoading: false, autoClose: 3000, closeButton: true });
    const handleCheckoutButton = ()=>{
      const currentTimeCode = moment().format('A')
      if(infoData.sessionCode === 'PM'){
        if(infoData.sessionCode !== currentTimeCode){
          toastError('You registered for afternoon session')
          return
        }
      }
      
      navigate('/scanner')
    }

    const subMenuHandleClick = e => {
      console.log('click ', e);
    };


    return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <div style={{flex: 1, maxWidth: '60vh', alignSelf: 'center'}}>
            <Menu 
              mode="horizontal" 
              theme='dark'
              triggerSubMenuAction='click'
            >
              <SubMenu 
                key="SubMenu"
                icon={<SettingOutlined style={{ fontSize: '20px'}} />}
                style={{ marginLeft: 'auto' }} title=""
              >
                <Menu.Item key="SubMenu:1">
                  <a id="home" className="menu-item" onClick={()=>{localStorage.removeItem('token');navigate("/");}}>Log out</a>
                </Menu.Item>
              </SubMenu>
            </Menu>
            <div style={{padding: 15}}>
            <img alt="peers-logo" width={'100%'} src='PEERS_LOGO_banner.png' />
            <h1 style={{alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(252, 186, 3, 0.4)', border: 'solid', borderWidth: 0}}>Welcome</h1>
            <Title align='center' level={4}>{(infoData?.name)? capitalizeFirstWord(infoData.name) : null}</Title>
            <p style={{textAlign: 'center', }}>{infoData?.programme}</p>
            <nav
                style={{
                    borderBottom: "solid 1px",
                }}
            />
            <div style={{padding: 10}} />
            <div style={{alignItems: 'center'}}>
                <div style={{borderRadius:'10px', backgroundColor: 'rgba(220, 231, 250, 0.6)', fontWeight: 'bold', alignItems: 'center', borderWidth: 0.5, borderStyle: 'solid'}}>
                    <div style={{paddingTop: '8px'}} />
                    <p style={{textAlign: 'center', }}>Check in time: {infoData?.checkIn&&moment(infoData.checkIn).format('Do MMMM YYYY, h:mm:ss a')}</p>
                </div>

                {infoData?.checkOut &&
                    <div style={{borderRadius:'10px', backgroundColor: 'rgba(220, 231, 250, 0.6)', fontWeight: 'bold', alignItems: 'center', borderWidth: 0.5, borderStyle: 'solid', marginTop: 15}}>
                        <div style={{paddingTop: '8px'}} />
                        <p style={{textAlign: 'center', }}>Check out time: {infoData?.checkOut&&moment(infoData.checkOut).format('Do MMMM YYYY, h:mm:ss a')}</p>
                    </div>
                }
                <div style={{padding: 10}} />
                {infoData?.checkOut &&
                    <Button onClick={()=>{navigate('/cert')}} style={{backgroundColor: '#26C281', width: '100%', height: 50, fontWeight: 'bold'}} type="primary">View certificate</Button>
                }
                {/* {!infoData?.checkOut &&
                  <Button onClick={()=>handleCheckoutButton()} style={{width: '100%', height: 50, fontWeight: 'bold'}} type="primary">Check out</Button>
                } */}
                
                <Button onClick={()=>{window.open('https://bit.ly/3PvWXNI','_blank','resizable=yes')}} style={{width: '100%', height: 50, fontWeight: 'bold'}} type="primary">Programme Book</Button>

                <div style={{padding: 10}} />
                <div style={{backgroundColor: 'rgba(220, 231, 250, 0.6)', fontWeight: 'bold', alignItems: 'center', padding: 5, borderWidth: 0, borderStyle: 'solid'}}>
                    <p style={{textAlign: 'center', fontSize: '16px'}}>Information View</p>
                    <table style={{marginLeft: 'auto', marginRight: 'auto'}} border = "0">
                    {infoData?.category && 
                      <tr>
                        <td align="right">Category :</td>
                        <td>{infoData.category}</td>
                      </tr>
                    }
                    {infoData?.displaySession && 
                      <tr>
                        <td align="right">Session :</td>
                        <td>{infoData.displaySession} </td>
                      </tr>
                    }
                    {infoData?.displaySessionSecond &&
                      <tr>
                        <td align="right">{infoData.displaySession? '' : 'Session :'}</td>
                        <td>{infoData.displaySessionSecond} </td>
                      </tr>
                    }
                    {infoData?.room &&
                      <tr>
                        <td align="right">Room :</td>
                        <td>{infoData.room}</td>
                      </tr>
                    }
                    </table>
                    <div style={{padding:10}} />
                </div>
            </div>
            </div>
            </div>
            <Footer />
            <div style={{padding: 25}}/>
              <div style={{position: 'fixed', left: 0, bottom: 0, width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: 1, width: '100%', maxWidth:'60vh', alignSelf: 'center'}}>
                {infoData?.category === "Panel Industry" ?
                  <div style={{display: 'flex', backgroundColor: 'rgba(8, 20, 44, 1)'}}>
                    <div onClick={()=>{window.open('https://docs.google.com/forms/d/e/1FAIpQLSe3AxZtrXcivWygbJkKDK6ByE6qCb3MNBgyRQIS6BsWOi4CFQ/viewform','_blank','resizable=yes')}} style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'whitesmoke'}} type="primary"><Activity size={16} /><div>Evaluation</div></div>
                    <div onClick={()=>{window.open('https://docs.google.com/forms/d/e/1FAIpQLSfjMeJTgK0n7EcCDnKCxyCmJhVNPmIzQykzlQ74a0m5nbQwWg/viewform','_blank','resizable=yes')}} style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'whitesmoke'}} type="primary"><Star size={16} /><div>Feedback</div></div>
                    <div onClick={()=>{navigate('/cert')}} style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'whitesmoke'}} type="primary"><File size={16} /><div>Certificate</div></div>
                  </div>
                  :
                  <div style={{display: 'flex', backgroundColor: 'rgba(8, 20, 44, 1)'}}>
                    <div onClick={()=>{window.open('https://docs.google.com/forms/d/e/1FAIpQLSfjMeJTgK0n7EcCDnKCxyCmJhVNPmIzQykzlQ74a0m5nbQwWg/viewform','_blank','resizable=yes')}} style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'whitesmoke'}} type="primary"><Star size={16} /><div>Feedback</div></div>
                    <div onClick={()=>{navigate('/cert')}} style={{ width: '100%', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: 'whitesmoke'}} type="primary"><File size={16} /><div>Certificate</div></div>
                  </div>
                }
                </div>
              </div>
        </div>
    );
}