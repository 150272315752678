import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import { GET_INFO, CHECKING_OUT } from '../redux/ducks/actions/ActionTypes';
import { Tooltip, AutoComplete, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
import { jsPDF } from "jspdf";

export default function Cert() {

    const componentRef = useRef();
    const dispatch = useDispatch();
    const infoData = useSelector((state) => state.init.infoData)

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if(!infoData) {
                dispatch({
                    type: GET_INFO
                })
            }
            else{
                savePdf(infoData?.name?.toUpperCase(), infoData?.category);
                navigate('/dashboard');
            //   setTimeout(function() {
            //     setPageSize()
            //     window.print();
            //   }, 1000);
            //   setTimeout(function() {
            //     navigate('/dashboard')
            //   }, 2500);
            //   setPageSize()
            //   window.print();
            }
        };
    
        fetchData();
    }, [dispatch]);

    function capitalizeFirstWord(str){
        const arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
    }

    function setPageSize() {
        const style = document.createElement('style');
        style.innerHTML = `@page {size: A4 landscape}} @print {@page :footer {display: none} @page :header {display: none}}`;
        style.id = 'page-orientation';
        document.head.appendChild(style);
    }

    const centeredText = function(doc,text) {
        var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        return textOffset
    }

    const savePdf = (name, category='') => {
        const doc = new jsPDF({
            orientation: "landscape",
        });
        doc.setProperties({ title: name+"-cert.pdf" })
        doc.addImage("peers2022-cert.png", "PNG", 0, 0, 297, 210); // left top, width, height
        doc.text(name, centeredText(doc,name), 99);
        doc.text(category, centeredText(doc,category), 125);

        const link = document.createElement("a");
        link.href = doc.output('bloburl','_blank');
        link.download = name+"-cert.pdf";
        link.click();
    }

    return (
    //   <div style={{backgroundColor: 'green' }}>
    //     <div>
    //         <img style={{position: 'fixed', right:0, left:0, top:0, bottom:0, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}} alt="peers2022-cert"  height={"100%"} witdh={"100"} src='peers2022-cert.png' />
    //     </div>
    //     <div style={{position: 'fixed', right:0, left:0, top:300, bottom:0, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
    //         <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 24}}>{infoData?.name?.toUpperCase()}</p>
    //     </div>
    //     <div style={{position: 'fixed', paddingTop:385, right:0, left:0, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', height: '100%'}}>
    //         <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 24}}>{infoData?.category}</p>
    //     </div>
    //   </div>
    <div>
        <Button onClick={()=>{savePdf(infoData?.name?.toUpperCase(), infoData?.category)}}>Click here if it does not auto print</Button>
    </div>
    );
}