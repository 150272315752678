import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import { GET_INFO, CHECKING_OUT } from '../redux/ducks/actions/ActionTypes';
import { Tooltip, AutoComplete, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
import { useQueryParam, StringParam } from 'use-query-params';
import { toast } from 'react-toastify';

export default function Checkout() {

    const [id, setId] = useQueryParam('id', StringParam);

    const dispatch = useDispatch();
    const infoData = useSelector((state) => state.init.infoData)

    const navigate = useNavigate();
    const notify = () => toast("Checkout failed",{type: "error", isLoading: false, autoClose: 3000, closeButton: true });

    useEffect(() => {
        const fetchData = async () => {
            if(id) {
                dispatch({
                    type: CHECKING_OUT, id
                })
            }
            else{
              notify()
            }
            navigate('/dashboard')
        };
        
        fetchData();
    }, [dispatch, id, infoData]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 15, height: '100vh'}}>
            <h1 style={{alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(252, 186, 3, 0.4)', border: 'solid', borderWidth: 1}}>Checkout</h1>
        </div>
    );
}
