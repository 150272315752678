export function Footer() {
    return (
        <div style={{flex:0.2}}>
            <p style={{color: 'grey', textAlign: 'center', fontSize: 9}}>Powered by Zons Solution <a href="https://zons.io/">(https://zons.io)</a></p>
        </div>
    )
}

export function Header() {
    <>
        <p style={{color: 'grey'}}>MENU</p>
    </>
}