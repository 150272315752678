import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import { SEARCH_PARTICIPANT, LOGIN } from './redux/ducks/actions/ActionTypes';
import { Tooltip, AutoComplete, Input, Button, Typography, Card } from 'antd';
import 'antd/dist/antd.css';
import { Footer } from './components/util';
const { Title } = Typography;

export default function App() {

  const [showPassInst, setShowPassInst] = useState(false)
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if(token){
        navigate("/dashboard")
      }
    };

    fetchData();
  }, [dispatch]);

  const showSettings = (event) => {
    event.preventDefault();
  }



  const onSearch = val => {
    setName(val);
    if(val)dispatch({type: SEARCH_PARTICIPANT, name: val, setUid, setOptions, setIsLoading});
  };
  
  const onSelect = (val, option) => {
    setName(option.value);
    setUid(option.uid);
  };

  const globalLoad = useSelector((state) => state.init.globalLoad);

  return (
      <div style={{display: 'flex', flexDirection: 'column', padding: 15, minHeight: '100vh'}}>
        <div style={{flex: 1, maxWidth: '60vh', alignSelf: 'center'}}>
        <div style={{flex: 1}}>
          <img alt="peers-logo" width={'100%'} src='PEERS_LOGO_banner.png' />
          <Card title={<Title align='center'>Login</Title>}>
            <div style={{alignItems: 'center'}}>
            <div style={{padding: 10}} />
            <Tooltip placement="topLeft" title="Please search your name" defaultVisible={true} visible={(name === ""? true : false)} >
            <AutoComplete
              style={{
                width: '100%',
              }}
              options={options}
              onSelect={(val, option) => onSelect(val, option)}
              onSearch={onSearch}
              notFoundContent={name?isLoading?"Searching..":"Attendance not exist":"Enter your name"}
            >
              <Input.Search placeholder="Search Name" loading={isLoading}/>
            </AutoComplete>
            </Tooltip>
            <div style={{padding: 10}} />
            <Tooltip placement="bottomLeft" title={"Last 4 digit phone number. Eg:- 60136951509 (1509)"} visible={showPassInst}>
              <Input.Password onChange={e => setPassword(e.target.value)} onFocus={()=>{setShowPassInst(true)}} onBlur={()=>{setShowPassInst(false)}} placeholder="Password" />
            </Tooltip>
            <div style={{padding: 40}} />
            <Button onClick={()=>{dispatch({type: LOGIN, navigate, uid, password});}} disabled={!(uid && password.length === 4)} style={{width: '100%', height: 50, fontWeight: 'bold'}} type="primary">Login</Button>
          </div>
          </Card>
        </div>
        </div>
        <Footer />
      </div>
  );
}